import { AppConfig, UserSession, showConnect } from '@stacks/connect';


const appConfig = new AppConfig(['store_write', 'publish_data']);

export const userSession = new UserSession({ appConfig });

export function authenticate() {
  showConnect({
    appDetails: {
      name: 'DogeWorld',
      icon: window.location.origin + '/logo.svg',
    },
    redirectTo: '/',
    onFinish: () => {
      window.location.reload();
    },
    userSession: userSession,
  });
}

export function getUserData() {
  return userSession.loadUserData();
}


export function getBTCAddressP2TRMainnet() {
  
  const userData = getUserData();
  return userData.profile.btcAddress.p2tr.mainnet;
}

