import React, { Component } from 'react';
import { ThemeProvider, theme, CSSReset, ToastProvider } from '@blockstack/ui';
import { userSession } from '../auth';
import { Signin } from './Signin';
import { Header } from './Header';

class App extends Component {
  state = {
    userData: null,
  };

  handleSignOut = (e) => {
    e.preventDefault();
    this.setState({ userData: null });
    userSession.signUserOut(window.location.origin);
  };

  componentDidMount() {
    if (userSession.isSignInPending()) {
      userSession.handlePendingSignIn().then((userData) => {
        window.history.replaceState({}, document.title, '/');
        this.setState({ userData: userData });
      });
    } else {
      if (userSession.isUserSignedIn()) {
        this.setState({ userData: userSession.loadUserData() });
      }
    }
  }

  render() {

    return (
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <div className="site-wrapper">
            <div className="site-wrapper-inner">
              <Header />
              {this.renderSignin()}
            </div>
          </div>
        </ToastProvider>
        <CSSReset />
      </ThemeProvider>
    );
  }

  renderSignin() {
    if (!userSession.isUserSignedIn()) {
      return <Signin />;
    }

    // Altrimenti, se l'utente è connesso, non visualizziamo nulla.
    return null;
  }
}

export default App;
