import React, { useEffect, useState } from 'react';
import { Flex, Box, Text } from '@blockstack/ui';
import { getUserData, userSession, getBTCAddressP2TRMainnet } from '../auth';

const Auth = () => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    if (userSession.isUserSignedIn()) {
      const address = getBTCAddressP2TRMainnet();
      fetchAPI(address);
    }
  }, [])

  const fetchAPI = async (addressBTC) => {
    var url = `https://api.hiro.so/ordinals/v1/inscriptions?address=${addressBTC}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Create image Url for each id
        data.results.forEach(item => {
          const imgUrl = `https://api.hiro.so/ordinals/v1/inscriptions/${item.id}/content`; 
          setImageUrls(prevUrls => [...prevUrls, imgUrl]);
        })
      })
      .catch(e => console.log(e));
  }

  if (!userSession.isUserSignedIn()) {
    return null;
  } else {
    return (
      <Box>
        <Text fontWeight="500">{getUserData().username}</Text>
        <Text fontWeight="500" display="inline-block" ml={5} color="black" cursor="pointer" onClick={() => { userSession.signUserOut(); window.location = '/'; }} >
          Sign out
        </Text>
        <div>
          {/* Displaying the images from the API data */}
          {imageUrls.length > 0 ? imageUrls.map((url, index) => <img src={url} key={index} alt="" width="100" height="100" />) : null} 
        </div>
      </Box>
    );
  }
};

export const Header = () => {
  return (
    <Flex width="100%" justifyContent="space-between" px={4} py={3}>
      <Box alignItems onClick={() => (document.location = '/')} cursor="pointer">
        <Auth />
      </Box>
    </Flex>
  );
};